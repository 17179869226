import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#F2F2F2      ',
    color: 'grey',
    padding: '12px 16px',
    borderRadius: '8px',
    fontSize: '14px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  '& .MuiTooltip-arrow': {
    color: '#F2F2F2      ',
  },
}))

const InfoLine = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&:not(:last-child)': {
    marginBottom: '4px',
  },
})

const Dot = styled(Box)(({ color }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: color,
}))

export default function TooltipComponent({children, taken, futureLeaves, solde, color}) {
  const tooltipContent = (
    <Box>
      <InfoLine>
        <Dot color={color} />
        <span>Solde : {solde}</span>
      </InfoLine>
      <InfoLine>
        <Dot color={`${color}80`} />
        <span>Pris estimé : {futureLeaves}</span>
      </InfoLine>
      <InfoLine>
        <Dot color="#CBD2D9" />
        <span>Pris : {taken}</span>
      </InfoLine>
    </Box>
  )

  return (
    <CustomTooltip title={tooltipContent} arrow >
      {children}
    </CustomTooltip>
  )
}

