export const siteParamsDefaultPayload = {
  includes: [
    {
      relation: 'days',
    },
    {
      relation: 'leave_types.leave_type_sub_families',
    },
    {
      relation: 'leave_types.leaveTypeCategory',
    },
  ],
  scopes: [
    {
      name: 'withDifferentClientSettings',
    },
  ],
};
export const clientParamsDefaultPayload = {
  includes: [
    {
      relation: 'days',
    },
    { relation: 'leave_types.leave_type_sub_families' },
    {
      relation: 'leave_types.leaveTypeCategory',
    },
    {
      relation: 'leave_types',
      filters: [
        {
          field: 'leave_types.site_id',
          operator: '=',
          value: null,
        },
      ],
    },
  ],
};
