import congesService from '@data/congesService';

import ToastCustom from '@components/UI/ToastCustom';
import { saveAs } from 'file-saver';

export const downloadError = (err) => {
  const textError = err.join('\n');
  const blob = new Blob([textError], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, 'error.txt');
};

const validMimeTypes = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const standardImport = (event, toggleModal, reloadData) => {
  const { files } = event.target;
  const file = files[0];

  event.target.value = null;
  if (!validMimeTypes.includes(file.type)) {
    ToastCustom.error('Le fichier doit être au format CSV, XLS ou XLSX');
  } else {
    ToastCustom.status('Chargement de votre fichier en cours...');
    const formData = new FormData();

    formData.append('file', file);

    congesService
      .post('/v1/import-user-leave-count', formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      })
      .then((res) => {
        if (res.data.success === 'PartiallySucces') {
          ToastCustom.submitted(res.data.message);
          downloadError(res.data.errors);
        } else {
          ToastCustom.validated(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        downloadError(err);
        ToastCustom.error('anErrorOccurred');
      })
      .finally(() => {
        toggleModal();
        reloadData();
      });
  }
};

export const importCegidCP = (event, toggleModal, reloadData) => {
  const { files } = event.target;
  const file = files[0];

  event.target.value = null;

  if (!validMimeTypes.includes(file.type)) {
    ToastCustom.error('Le fichier doit être au format CSV, XLS ou XLSX');
  } else {
    ToastCustom.status('Chargement de votre fichier en cours...');
    const formData = new FormData();

    formData.append('file', file);
    formData.append('queueName', 'cegid-cp');

    congesService
      .post('/v1/import-user-leave-count', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success === 'PartiallySucces') {
          ToastCustom.submitted(res.data.message);
          downloadError(res.data.errors);
        } else {
          ToastCustom.validated(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        downloadError(err);
      })
      .finally(() => {
        toggleModal();
        reloadData();
      });
  }
};

export const importCegidOther = (event, toggleModal, reloadData) => {
  const { files } = event.target;
  const file = files[0];

  event.target.value = null;

  if (!validMimeTypes.includes(file.type)) {
    ToastCustom.error('Le fichier doit être au format CSV, XLS ou XLSX');
  } else {
    ToastCustom.status('Chargement de votre fichier en cours...');
    const formData = new FormData();

    formData.append('file', file);
    formData.append('queueName', 'cegid-others');

    congesService
      .post('/v1/import-user-leave-count', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success === 'PartiallySucces') {
          ToastCustom.submitted(res.data.message);
          downloadError(res.data.errors);
        } else {
          ToastCustom.validated(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        downloadError(err);
      })
      .finally(() => {
        toggleModal();
        reloadData();
      });
  }
};

export const importSilae = (event, toggleModal, reloadData) => {
  const { files } = event.target;
  const file = files[0];

  event.target.value = null;

  if (!validMimeTypes.includes(file.type)) {
    ToastCustom.error('Le fichier doit être au format CSV, XLS ou XLSX');
  } else {
    ToastCustom.status('Chargement de votre fichier en cours...');
    const formData = new FormData();

    formData.append('file', file);

    congesService
      .post('/v1/import-user-leave-count', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success === 'PartiallySucces') {
          ToastCustom.submitted(res.data.message);
          downloadError(res.data.errors);
        } else {
          ToastCustom.validated(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        downloadError(err);
      })
      .finally(() => {
        toggleModal();
        reloadData();
      });
  }
};
