import React from 'react';
import InputCustom from '../../../../../UI/InputCustom';
import { t } from 'i18next';
import ColorPicker from '../../../../../UI/ColorPicker';
import { colorConvert } from '../../../../../../utility/Utils';
import { useFormikContext } from 'formik';

function MainAbsenceTypeSettings() {
  const { setFieldValue, values } = useFormikContext();
  if (!values)
    return (
      <div className='flex justify-center items-center h-full '>
        <p>Aucun type d'absence</p>
      </div>
    );

  return (
    <div className='grid grid-cols-3 gap-4 gap-x-8'>
      <div>
        <span className='text-gray-500 text-sm leading-4'>{t('label') + '*'}</span>
        <InputCustom
          name='name'
          id='name'
          type='text'
          maxLength={100}
          isRequired={true}
          value={values?.name}
          disabled={!values?.is_deletable}
          onChange={(e) => {
            setFieldValue('name', e.target.value);
          }}
        />
      </div>
      <div className=''>
        <span className='text-gray-500 text-sm leading-4'>{t('colors')} </span>
        <div className='flex items-center px-4 h-12 w-full rounded-md bg-lightgrey-50 pr-10 text-gray-600 placeholder-gray-600 focus:outline-none sm:text-sm'>
          <ColorPicker
            onChange={(e) => {
              setFieldValue('color', e.target.value);
            }}
            color={colorConvert(values?.color)}
            containerClasses='my-auto'
          />
        </div>
      </div>
      <div>
        <span className='text-gray-500 text-sm leading-4'>{`Code de l'abscence *`}</span>
        <InputCustom
          name='code'
          id='code'
          type='text'
          maxLength={5}
          isRequired={true}
          value={values?.leave_code}
          onChange={(e) => {
            setFieldValue('leave_code', e.target.value);
          }}
        />
      </div>
    </div>
  );
}

export default MainAbsenceTypeSettings;
