import React, { useState } from "react"
import ExpandMore from '@mui/icons-material/ExpandMore';

const Definition = () => {
    const [open, setOpen] = useState(false)

    return (
        <div className="font-lato text-[#666666]">
            <div 
                className="inline-flex items-center space-x-1 cursor-pointer" 
                onClick={() => setOpen(!open)}
            >
                <p><b>Définition</b></p>
                <div 
                    className={`transition-transform duration-300 ease-in-out transform ${open ? 'rotate-180' : ''}`}
                >
                    <ExpandMore/>
                </div>
            </div>
            
            <div 
                className={`
                    overflow-hidden transition-all duration-300 ease-in-out
                    ${open ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}
                `}
            >
                <div className="space-y-2 mt-2">
                    {[
                        { 
                            title: "Acquis", 
                            description: "Désigne l'ensemble des jours de congé que le collaborateur a récupérés durant la période de référence." 
                        },
                        { 
                            title: "Pris estimé", 
                            description: "Correspond aux jours de congé validés par le ou les managers, qui n'ont pas encore été transmis en paie, et donc qui ne sont pas encore considérés comme \"pris\"." 
                        },
                        { 
                            title: "Pris", 
                            description: "Représente les jours de congé validés par les managers, intégrés à la paie et donc reflétés sur la fiche de paie du collaborateur." 
                        },
                        { 
                            title: "Solde", 
                            description: "Indique le nombre de jours de congé restants, après avoir retiré les jours \"pris\" et \"pris estimé\"." 
                        }
                    ].map((item, index) => (
                        <div 
                            key={item.title}
                            className={`
                                space-y-1 opacity-0 transform -translate-y-5
                                transition-all duration-300 ease-in-out
                                ${open ? 'animate-fade-in-slide' : ''}
                            `}
                            style={{
                                animationDelay: `${index * 0.1}s`
                            }}
                        >
                            <p>
                                <b>{item.title} : </b>{item.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Definition;