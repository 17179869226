import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalCustom from '@components/UI/ModalCustom';

import { Form, Formik } from 'formik';
import InputCustom from '@components/UI/InputCustom';
import ToastCustom from '@components/UI/ToastCustom';
import FileUploader from '@components/UI/FileUploader';
import { ButtonFirstAction, ButtonSecondAction } from '@components/UI/Buttons';
import UserContext from '@context/UserContext';

import congesService from '@data/congesService';

import PropTypes from 'prop-types';

const DocumentationModal = ({
  isModalOpen,
  setIsModalOpen,
  fetchDocuments,
}) => {
  const { t } = useTranslation();

  const [sites, setSites] = useState([]);
  const { currentUser } = useContext(UserContext);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onModalClose = () => {
    toggleModal();
  };

  const handleSubmit = async (values, actions) => {
    try {
      const resultSite = [];
      values.ids.forEach((elem) => resultSite.push(elem.id));

      const sitesTab = values.ids.map((item) => item.id);

      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('creator_id', currentUser.id);
      formData.append('media[0][file]', values.documentation[0]);
      formData.append('media[0][collection]', 'support_document');
      sitesTab.forEach((site, index) => {
        formData.append(`sites[${index}]`, site);
      });

      if (values.id) {
        await congesService.post(`/v1/support-documents`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        ToastCustom.validated('successStore');
      } else {
        await congesService.post('/v1/support-documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        ToastCustom.validated('successUpdate');
      }

      fetchDocuments();
    } catch (err) {
      ToastCustom.error();
      console.error(err);
    } finally {
      actions.setSubmitting(false);
      toggleModal();
    }
  };

  const loadOptions = async (searchQuery, loadedOptions, additional) => {
    const currentPage = additional?.page || 1;

    try {
      const response = await congesService.post(`/v1/sites/search?&page=${currentPage}`, {
        search: {
          value: searchQuery,
        },
      });

      const { data, meta } = response.data;

      const options = data.map(site => ({
        id: site.id,
        name: site.name,
      }));

      setSites((prev) => [...prev, ...options]);

      return {
        options,
        hasMore: meta.current_page < meta.last_page,
        additional: {
          page: currentPage + 1,
        },
      };
    } catch (err) {
      console.error("Erreur lors du chargement des sites :", err);

      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <>
      <ModalCustom
        title={t('addDocument')}
        isOpen={isModalOpen}
        onModalClose={onModalClose}
        customClass={'w-1/2 md:w-[900px]'}
      >
        <div className='w-full mt-4'>
          <Formik
            initialValues={{
              ids: [],
              id: '',
              name: '',
              documentation: [],
            }}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = t('requiredFields');
              }
              if (!values.ids.length) {
                errors.ids = t('requiredFields');
              }
              if (!values.documentation.length) {
                errors.documentation = t('requiredFields');
              }
              return errors;
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit} id='documentation-form'>
                <InputCustom
                  name='name'
                  type='text'
                  label={t('titleDocument') + ' *'}
                />
                <InputCustom
                  name='ids'
                  label={t('concernedSite')}
                  type='selectBox'
                  placeholder={'Rechercher des sites : '}
                  containerClass='my-4'
                  isMulti={true}
                  options={sites}
                  loadOptions={loadOptions}
                />
                <div className=''>
                  <span className='block text-xs font-medium text-gray-700 mb-1'>
                    {t('file')} *
                  </span>
                  <FileUploader
                    type='fileUploader'
                    containerClass='mb-3 pb-4'
                    handleRemoveFile={() => setFieldValue('documentation', [])}
                    files={values.documentation}
                    setFiles={(e) => setFieldValue('documentation', e)}
                    name='documentation'
                    isMultiple={false}
                  />
                </div>
                <div className='flex items-center justify-end gap-x-3'>
                  <ButtonSecondAction onClick={toggleModal} type='button'>
                    {t('cancel')}
                  </ButtonSecondAction>
                  <ButtonFirstAction type='submit'>
                    {t('continue')}
                  </ButtonFirstAction>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ModalCustom>
    </>
  );
};

export default DocumentationModal;

DocumentationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
