// ** Default import

import { useState } from 'react';

// ** Utils

import { useTranslation } from 'react-i18next';
import congesService from '@src/data/congesService';
import { getFullname } from '@utility/Utils';

// ** Teams filters config

const TeamConfig = (
  setFilter,
  getData,
  tags,
  setIsFilterModalOpen,
  selectedOptions,
  setSelectedOptions
) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const handleSubmit = (e) => {
    buildFilter(selectedOptions);
    setIsFilterModalOpen(false);
  };

  const handleClose = () => {
    setIsFilterModalOpen(false);
  };

  const buildFilter = (options) => {
    let filterToSend = [];
    Object.keys(options).forEach((key) => {
      if (options[key] && options[key].length > 0) {
        if (Array.isArray(options[key])) {
          if (key === 'enter_date') {
            filterToSend.push({
              field: key,
              operator: '>=',
              value: options[key][0],
            });
            filterToSend.push({
              field: key,
              operator: '<=',
              value: options[key][1],
            });
          } else {
            filterToSend.push({
              field: key,
              operator: 'in',
              value: options[key].map((item) => item.id),
            });
          }
        }
      }
    });
    getData(1, filterToSend);
    setFilter(filterToSend);
  };

  const loadOptions = async (inputValue) => {
    const response = await congesService.post(`v1/users/search?page=${page}`, {
      sort: [{ field: 'lastname', direction: 'asc' }],
      'search-users': {
        value: inputValue,
      },
    });
    const meta = response.data.meta;
    setPage(meta.current_page < meta.last_page ? meta.current_page + 1 : 1);
    return {
      options: response.data.data.map((res) => {
        return {
          ...res,
          name: getFullname(res),
        };
      }),
      hasMore:
        response.data.meta?.current_page !== response.data.meta?.last_page,
    };
  };

  const loadSite = async () => {
    const response = await congesService.get(`v1/sites`);
    return {
      options: response.data.data.map((res) => {
        return {
          ...res,
          name: `${res.name}`,
        };
      }),
      hasMore: false,
    };
  };

  const handleReset = () => {
    setSelectedOptions({});
    setFilter([]);
    getData(1, []);
  };

  const fields = [
    {
      name: 'id',
      label: t('Collaborateurs'),
      type: 'asyncPaginate',
      value: selectedOptions['id'] ?? [],
      placeholder: t('collaborators'),
      notFoundMessage: 'Aucun collaborateur trouvé',
      optionLabel: 'name',
      optionValue: 'id',
      isMulti: true,
      onChange: (e) => {
        setSelectedOptions({
          ...selectedOptions,
          id: e,
        });
      },
      loadOptions: loadOptions,
      additional: {
        page: page,
      },
    },
    {
      name: 'tags.id',
      label: t('teams'),
      type: 'select',
      options: tags,
      value: selectedOptions['tags.id'] ?? [],
      placeholder: t('teams'),
      optionLabel: 'name',
      optionValue: 'id',
      isMulti: true,
      onChange: (e) => {
        setSelectedOptions({
          ...selectedOptions,
          'tags.id': e,
        });
      },
    },
    {
      name: 'site.id',
      label: t('site'),
      type: 'asyncPaginate',
      loadOptions: loadSite,
      value: selectedOptions['site.id'] ?? [],
      placeholder: t('site'),
      optionLabel: 'name',
      optionValue: 'id',
      isMulti: true,
      onChange: (e) => {
        setSelectedOptions({
          ...selectedOptions,
          'site.id': e,
        });
      },
    },
  ];

  return {
    title: t('filters'),
    fields,
    handleSubmit: handleSubmit,
    handleClose: handleClose,
    handleReset: handleReset,
  };
};

export default TeamConfig;
