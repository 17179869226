// ** Default import
import React, {
  useState,
  Fragment,
  Suspense,
  useContext,
  useTransition,
} from 'react';

// ** Utils

import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

import Spinner from '@src/components/UI/Spinner';

import NotificationContext from '@context/NotificationContext';
import { Link } from 'gatsby';

import { classNames } from '@utility/Utils';
import NotificationBadge from '@components/UI/NotificationBadge';

// ** Send to accounting

const RequestProcessed = React.lazy(() =>
  import('@pages/sendToAccounting/requestProcessed')
);
const RequestToProcess = React.lazy(() =>
  import('@pages/sendToAccounting/requestToProcess')
);
const ExportsHistory = React.lazy(() =>
  import('@pages/sendToAccounting/exportsHistory')
);

const SendToAccounting = () => {
  const { t } = useTranslation();
  const [isPending, startTransition] = useTransition();

  const location = useLocation();

  const { getAccountingNotif } = useContext(NotificationContext);
  const [tabs, setTabs] = useState([
    {
      label: t('requestToProcess'),
      name: '',
      content: <RequestToProcess />,
      active: location.hash.replace('#', '') === '',
    },
    {
      label: t('requestProcessed'),
      name: 'accountingProcessed',
      content: <RequestProcessed />,
      active: location.hash.replace('#', '') === 'accountingProcessed',
    },
    {
      label: t('exportsHistory'),
      name: 'exportsHistory',
      content: <ExportsHistory />,
      active: location.hash.replace('#', '') === 'exportsHistory',
    },
  ]);

  // set the tab active
  const setActive = (tab) => {
    startTransition(() => {
      setTabs((prevState) => {
        return prevState.map((t) => {
          return {
            ...t,
            active: t.name === tab,
          };
        });
      });
    });
  };
  return (
    <>
      <h1 className={'mb-6'}>{t('sendToAccounting')}</h1>
      <div className='bg-white rounded-md p-7 min-h-[101vh]'>
        <nav className='flex justify-around' aria-label='Tabs'>
          {tabs.map((tab, index) => (
            <Link
              key={index}
              to={`#${tab.name}`}
              onClick={() => setActive(tab.name)}
              className={classNames(
                tab.active
                  ? 'border-b-2 border-primary1 transition duration-300 ease-in'
                  : 'border-b-2 border-[#E1060020] transition duration-300 ease-in-out',
                'text-gray-500 w-full flex items-center justify-center gap-x-6 hover:text-gray-700 pb-2'
              )}
            >
              <span>{tab.label}</span>
              {!tab.name && getAccountingNotif > 0 && (
                <NotificationBadge
                  numberOfNotification={
                    getAccountingNotif > 99 ? '99+' : getAccountingNotif
                  }
                />
              )}
            </Link>
          ))}
        </nav>

        {isPending ? (
          <Spinner />
        ) : (
          <Suspense fallback={<Spinner />}>
            <div className={'mt-4'}>
              {tabs.map((tab, index) => (
                <Fragment key={index}>{tab.active && tab.content}</Fragment>
              ))}
            </div>
          </Suspense>
        )}
      </div>
    </>
  );
};

export default SendToAccounting;
