// ** Default import

import React, { useContext, useEffect, useState } from 'react';

// ** Utils
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import congesService from '@data/congesService';
import { useTranslation } from 'react-i18next';
import { classNames } from '@utility/Utils';
import dayjs from 'dayjs';

// ** UI

import Spinner from '@components/UI/Spinner';

import Badge from '@components/UI/Badge';

import UserContext from '@context/UserContext';

import Lottie from 'lottie-react';
import empty from '@assets/lottie/empty.json';
import LeaveResume from '@helpers/LeaveResume';
import { guidingTool } from '../utility/Utils';
import useStatus from '../hooks/useStatus';
import CustomGaugeChart from './counter/customGauge';
import Definition from './counter/definition';

// ** Dayjs declare internalization
dayjs.extend(localizedFormat);

const circleGrey =
  'h-[12px] w-[12px] text-lightgrey-600 bg-[#E5E5E5]  p-3 font-semibold rounded-full flex items-center justify-center ';
const circleRed =
  'h-[16px] w-[16px] text-primary1 bg-[#F9E4E4] p-3 font-semibold rounded-full flex items-center justify-center ';

const Dashboard = () => {
  const [leaves, setLeaves] = useState([]);
  const [userCounters, setUserCounters] = useState([]);
  const [currentLeave, setCurrentLeave] = useState({});
  const [resumeModaleOpen, setResumeModaleOpen] = useState(false);
  const [metaData, setMetaData] = useState({
    current_page: 1,
    last_page: 1,
    total: 1,
  });
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const getStatusByTag = useStatus();

  const { currentUser } = useContext(UserContext);

  const fetchUsersCounter = async () => {
    try {
      const response = await congesService.post(
        '/v1/user-leaves-count/search?include=leave_type,futureLeaves',
        {
          filters: [
            {
              field: 'user_id',
              operator: '=',
              value: currentUser.id,
            },
          ],
        }
      );
      setUserCounters(
        response.data.data
          .map((element) => {
            return {
              ...element,
              balance:
                Math.round(
                  (element.acquired - Math.abs(element.futureLeaves) - element.taken) *
                  100
                ) / 100,
            };
          })
          .sort(
            (counters) =>
              counters.order_appeareance < counters.order_appeareance
          )
      );
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLeaves = async ({ signal }) => {
    try {
      const response = await congesService.post(
        `/v1/leaves/search?include=status,user,user.validators,leave_type,histories,histories.status,histories.user&page=${metaData.current_page}&limit=3`,
        {
          filters: [
            {
              field: 'user_id',
              operator: '=',
              value: currentUser.id,
            },
            {
              field: 'start_date',
              operator: '>=',
              value: dayjs().format('YYYY-MM-DD'),
            },
            {
              field: 'status.tag',
              operator: 'not in',
              value: ['REFUSED', 'CANCELED'],
            },
          ],
          sort: [
            {
              field: 'start_date',
              direction: 'asc',
            },
          ],
        },
        { signal }
      );
      setLeaves(response.data.data);
      setMetaData((prevState) => ({
        ...prevState,
        last_page: response.data.meta.last_page,
        total: response.data.meta.total,
      }));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchUsersCounter();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchLeaves(signal).then(() => setIsLoading(false));
    return () => {
      abortController.abort();
    };
  }, [metaData.current_page]);

  useEffect(() => {
    if (['rd', 'demo'].includes(process.env.GATSBY_APP_ENV_TYPE)) {
      guidingTool(
        window,
        document,
        'https://www.usetiful.com/dist/usetiful.js'
      );
    }
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {resumeModaleOpen && (
        <LeaveResume
          currentLeave={currentLeave}
          isModalOpen={resumeModaleOpen}
          setIsModalOpen={setResumeModaleOpen}
          getData={fetchLeaves}
          needCancel
        />
      )}
      <h1 className='text-2xl mb-6'>{t('home')}</h1>
      <div className='bg-white rounded-md p-7 min-h-[320px]'>
        <div className='flex items-center justify-between'>
          <h3 className='text-primary1 text-lg font-semibold mb-3'>
            {t('myLeavesToCome')}
          </h3>
          <div className='flex items-center mx-6'>
            <button
              onClick={() =>
                setTimeout(() => {
                  metaData.current_page !== 1 &&
                    setMetaData((prevState) => ({
                      ...prevState,
                      current_page: metaData.current_page - 1,
                    }));
                }, 200)
              }
              className={classNames(
                metaData.current_page > 1 ? `${circleRed}` : `${circleGrey}`,
                'm-1'
              )}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span
              className={classNames(
                metaData.current_page === metaData.last_page
                  ? 'text-lightgrey-600'
                  : 'text-primary1',
                ''
              )}
            >
              {metaData.current_page}
            </span>
            <button
              onClick={() => {
                setTimeout(() => {
                  metaData.current_page < metaData.last_page &&
                    setMetaData((prevState) => ({
                      ...prevState,
                      current_page: metaData.current_page + 1,
                    }));
                }, 200);
              }}
              className={classNames(
                metaData.current_page === metaData.last_page
                  ? `${circleGrey}`
                  : `${circleRed}`,
                'm-1 '
              )}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
        <div className='max-h-3/5 lg:px-8'>
          <div className='mx-auto'>
            <ul className='flex flex-col gap-y-2 my-2'>
              {leaves.length > 0 ? (
                leaves.map((leave, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        setCurrentLeave(leave);
                        setResumeModaleOpen(true);
                      }}
                      className={
                        'shadow-leave bg-white flex justify-between flex-1 items-center w-full h-12 px-6 py-4 rounded-[15px] cursor-pointer hover:bg-lightgrey-50 transition-all duration-200 ease-in-out'
                      }
                    >
                      <span className='whitespace-nowrap text-xxs lg:text-sm font-bold text-gray-900 min-w-max w-1/4 text-ellipsis flex-2'>
                        {t(leave.leave_type.name)}
                      </span>
                      <div className='whitespace-nowrap text-xxs lg:text-sm text-gray-500 min-w-1/6 text-ellipsis flex-2'>
                        {`${t('from')} ${dayjs(leave.start_date).format(
                          'L'
                        )} ${t('at')} ${dayjs(leave.end_date).format('L')}`}
                      </div>
                      <span className='max-w-12 ml-5 text-center text-ellipsis text-xxs lg:text-sm font-semibold'>
                        {`${leave.duration} ${t(
                          leave.duration > 1 ? 'days' : 'day'
                        ).toLowerCase()}`}
                      </span>
                      <div className='whitespace-nowrap text-sm lg:text-sm text-gray-500 w-28 md:w-32'>
                        <Badge
                          value={t(getStatusByTag(leave.status.tag))}
                          color={leave.status.color}
                          className='flex items-center justify-center text-xs md:text-md '
                        />
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className='flex flex-col items-center justify-center'>
                  <Lottie animationData={empty} className='w-64 h-64' />
                  <h4>{t('noFutureLeaves')}</h4>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className='flex-column justify-center bg-white rounded-md p-7 mt-8'>
        <div className="flex items-center justify-between">
          <h2 className="text-primary1 text-lg font-semibold">
            {t('myCounters')}
          </h2>
          {userCounters?.length > 0 && userCounters[0].rh_update && (
            <div className="font-lato text-[#666666]">
              <p>
                Date de mise à jour : {dayjs(userCounters[0].rh_update).format('DD/MM/YYYY')}
              </p>
            </div>
          )}
        </div>
        <div className='flex justify-center items-center flex-wrap gap-12 w-auto '>
          {userCounters.map((userLeaveCount, index) => {
            if (userLeaveCount.leave_type.order_appearance === 0) return null;
            const { acquired, taken, balance, futureLeaves, leave_type, is_last_year } = userLeaveCount;

            return (
              <CustomGaugeChart
                key={index}
                acquired={acquired}
                solde={balance}
                taken={taken}
                futureLeaves={futureLeaves}
                color={leave_type.color.replace(/0xFF|0xff/, '#')}
                title={leave_type.name}
                lastYear={is_last_year}
              />
            )
          })
          }
        </div>
        <Definition />
      </div>
    </>
  );
};

export default Dashboard;
