import React, { useEffect, useState } from 'react';

import SlideOver from '@components/UI/SlideOver';

import { getFullname } from '@utility/Utils';
import CustomGaugeChart from '../../pages/counter/customGauge';

import congesService from '@data/congesService';

const CounterSlideOver = ({ isOpen, setIsOpen, values }) => {
  const onClose = () => setIsOpen(false);

  const [userLeaveCount, setUserLeaveCount] = useState([]);

  const fetchCounters = async () => {
    const response = await congesService.post(
      '/v1/user-leaves-count/search?include=futureLeaves,leave_type',
      {
        filters: [
          {
            field: 'user_id',
            operator: '=',
            value: values.id,
          },
        ],
      }
    );
    setUserLeaveCount(
      response.data.data.map((element) => {
        return {
          ...element,
          balance:
            Math.round(
              (element.acquired - element.futureLeaves - element.taken) * 100
            ) / 100,
        };
      })
    );
  };

  useEffect(() => {
    fetchCounters();
  }, [isOpen]);

  return (
    <>
      <SlideOver
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={onClose}
        title={`${getFullname(values)}`}
      >
        <div className='flex flex-col justify-center items-center gap-y-6 mt-6'>
          {userLeaveCount.map((userLeaveCount, index) => {
            if (userLeaveCount.leave_type.order_appearance === 0) return null;
            const { acquired, taken, balance, futureLeaves, leave_type, is_last_year } = userLeaveCount;
            return (
                <div>
                  <CustomGaugeChart
                    key={index}
                    acquired={acquired}
                    solde={balance}
                    taken={taken}
                    futureLeaves={futureLeaves}
                    color={leave_type.color.replace(/0xFF|0xff/, '#')}
                    title={leave_type.name}
                    lastYear={is_last_year}
                  />
                </div>
            )
          })
          }
        </div>
      </SlideOver>
    </>
  );
};

export default CounterSlideOver;
