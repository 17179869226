import congesService from '@data/congesService';

export const dayoffClientFetch = async (id, year, countryAlpha) => {
  try {
    const response = await congesService.post(`/v1/holidays/search?limit=100`, {
      filters: [
        {
          field: 'year',
          value: year,
        },
        {
          field: 'country_code',
          value: countryAlpha,
        },
      ],
      aggregates: [
        {
          relation: 'clients',
          type: 'exists',
          filters: [
            {
              field: 'clients.id',
              value: id,
            },
          ],
        },
      ],
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error?.message);
  }
};

export const fetchLeavesType = async () => {
  try {
    const response = await congesService.post(
      `/v1/leave-types/search?include=leaveTypeCategory`,
      {
        filters: [
          {
            field: 'is_active',
            operator: '=',
            value: true,
          },
          {
            field: 'leaveTypeCategory.slug',
            operator: '=',
            value: 'abs',
          },
        ],
        sort: [
          {
            field: 'order_appearance',
            direction: 'asc',
          },
        ],
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error?.message);
  }
};

export const dayoffSiteFetch = async (id, year, countryAlpha) => {
  try {
    const response = await congesService.post(`/v1/holidays/search?limit=100`, {
      filters: [
        {
          field: 'year',
          value: year,
        },
        {
          field: 'country_code',
          value: countryAlpha,
        },
      ],
      aggregates: [
        {
          relation: 'sites',
          type: 'exists',
          filters: [
            {
              field: 'sites.id',
              value: id,
            },
          ],
        },
      ],
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error?.message);
  }
};

export const handleDayOffFetch = (site, ...args) => {
  switch (site) {
    case 'default':
      return dayoffClientFetch(...args);
    case 'site':
      return dayoffSiteFetch(...args);
    default:
      Promise.reject();
      break;
  }
};

export const updateDayOffClient = async (clientId, dayoffIds) => {
  try {
    const response = await congesService.patch(
      `/v1/clients/${clientId}/holidays/sync`,
      {
        resources: [...dayoffIds],
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const updateDayOffSite = async (siteId, dayoffIds) => {
  try {
    const response = await congesService.patch(
      `/v1/sites/${siteId}/holidays/sync`,
      {
        resources: [...dayoffIds],
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const createDayOff = async (name, date, id) => {
  try {
    const response = await congesService.post(`/v1/holidays`, {
      name,
      date,
      ...id,
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
    const message = error.response?.data?.message;
    const leaveTaken = 'validation.custom.leave_already_taken';
    if (message === leaveTaken)
      throw new Error('Des congés ont déjà été prise à cette date');
    else throw new Error();
  }
};

export const deleteDayOff = async (id) => {
  try {
    const response = await congesService.delete(`/v1/holidays/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
