import { createContext } from 'react';

const initialPlanningContext = {
  users: () => { },
  setUsers: () => { },
  setPage: () => { },
  t: () => { },
  period: 'twomonths',
  setPeriod: () => { },
  reversePrimary: true,
  setReversePrimary: () => { },
  week: 1,
  month: 1,
  year: 2023,
  changeWeek: () => { },
  changeMonth: () => { },
  daysOfPeriod: [],
  usersGroupedBySite: [],
  publicHolidays: [],
  hoverUser: null,
  setHoverUser: () => { },
  detachUser: () => { },
  addUser: () => { },
  reloadLeaves: false,
  setReloadLeaves: () => { },
};
const PlanningContext = createContext(initialPlanningContext);

export default PlanningContext;
