import React, { useState, useEffect, useContext } from 'react';

import {
  faCircleInfo,
  faInfoCircle,
  faPlus,
  faPlusLarge,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { isObjEmpty } from '@utility/Utils';
import UserContext from '@context/UserContext';
import congesService from '@data/congesService';

import Spinner from '@components/UI/Spinner';
import { ButtonFilled, ButtonFirstAction } from '@components/UI/Buttons';
import OpenDaysCheckbox from '@components/Setting/Leaves/OpenDaysCheckbox';
import InputCustom from '@components/UI/InputCustom';
import ColorPicker from '@components/UI/ColorPicker';
import MaxConsecutiveDays from '@components/Setting/Leaves/MaxConsecutiveDays';
import AdvancedSettings from '@components/Setting/Leaves/AdvancedSettings';
import ToastCustom from '@components/UI/ToastCustom';
import LeaveTypesList from '@components/Setting/Leaves/LeaveTypesList';
import { defaultAbsenceType, initialLeave } from '../../components/Setting/Leaves/data/params';

import SwalCustom from '@helpers/SwalCustom';


function Leaves() {
  const [leaveTypes, setLeaveTypes] = useState(initialLeave);
  const [absences, setAbsences] = useState(defaultAbsenceType);
  const [selectedLeaveType, setSelectedLeaveType] = useState({});

  const [initialValues, setInitialValues] = useState({
    open_days: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
  });

  const [initialLeavesTypes, setInitialLeavesTypes] = useState([]);
  const [initialAbsences, setInitialAbsences] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const fetchLeaveTypes = async () => {
    try {
      const res = await congesService.post(
        '/v1/leave-types/search?include=leave_type_sub_families',
        {
          sort: [
            {
              field: 'order_appearance',
              direction: 'asc',
            },
          ],
        },
      );

      const formatInitialDate = res.data.data.map((leaveType) => {
        return {
          ...leaveType,
          start_date: leaveType.start_date ?? '',
          end_date: leaveType.end_date ?? '',
          isDefine: leaveType.start_date && leaveType.end_date,
        };
      });

      const canEveryoneSee = [];

      const canOnlyBeSeenByAdmin = [];

      formatInitialDate.forEach((leave) => {
        if (leave.is_only_visible_by_admin) {
          canOnlyBeSeenByAdmin.push(leave);
        } else {
          canEveryoneSee.push(leave);
        }
      });

      setLeaveTypes(canEveryoneSee);
      setAbsences(canOnlyBeSeenByAdmin);
      setInitialAbsences(canOnlyBeSeenByAdmin);
      setInitialLeavesTypes(canEveryoneSee);
      setSelectedLeaveType(canEveryoneSee[0]);
    } catch (err) {
      console.error(err);
      ToastCustom.error(t(err?.response?.data?.message));
    }
  };

  const fetchClient = async () => {
    try {
      const response = await congesService.get(`v1/clients/current`);
      const openDaysNumber = response.data.open_days;
      setInitialValues({
        ...response.data,
        open_days: {
          monday: openDaysNumber.includes(1),
          tuesday: openDaysNumber.includes(2),
          wednesday: openDaysNumber.includes(3),
          thursday: openDaysNumber.includes(4),
          friday: openDaysNumber.includes(5),
          saturday: openDaysNumber.includes(6),
          sunday: openDaysNumber.includes(7),
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const initData = async () => {
    try {
      await fetchLeaveTypes();
      await fetchClient();
    } catch (err) {
      console.error(err);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const handleSubmitAbsentee = async () => {
    try {
      if (selectedLeaveType.id) {
        const res = await congesService.put(
          `/v1/leave-types/${selectedLeaveType.id}?withLeaveTypeSubFamilies=1`,
          selectedLeaveType
        );
        setAbsences((prevState) =>
          prevState.map((prevState) =>
            prevState.id === selectedLeaveType.id ? res.data.data : prevState
          )
        );
        setSelectedLeaveType(res.data.data);
        ToastCustom.validated(t('successUpdate'));
      } else {
        const res = await congesService.post(
          '/v1/leave-types',
          selectedLeaveType
        );
        ToastCustom.validated(t('successCreate'));
        setAbsences((prevState) => [...prevState, res.data.data]);
        setSelectedLeaveType(res.data.data);
      }
    } catch (err) {
      console.error(err);
      ToastCustom.error(t(err?.response?.data?.message));
    }
  };

  const handleSubmit = async () => {
    let dataToSend = {
      ...selectedLeaveType,
      start_date: selectedLeaveType.start_date
        ? selectedLeaveType.start_date
        : null,
      end_date: selectedLeaveType.end_date ? selectedLeaveType.end_date : null,
    };
    //@TODO Check with CDP if we need to add a counter everytime

    if (selectedLeaveType.leave_type_sub_families?.length) {
      selectedLeaveType.leave_type_sub_families.forEach((subFamily) => {
        if (typeof subFamily.id === 'string') {
          delete subFamily.id;
        }
      });
    }

    try {
      if (selectedLeaveType.id) {
        const res = await congesService.put(
          `/v1/leave-types/${selectedLeaveType.id}?withLeaveTypeSubFamilies=1`,
          dataToSend
        );
        const startDate = res.data.data.start_date ?? '';
        const endDate = res.data.data.end_date ?? '';

        const formatedReponse = {
          ...res.data.data,
          start_date: startDate,
          end_date: endDate,
        };

        setLeaveTypes((prevState) =>
          prevState.map((prevState) =>
            prevState.id === selectedLeaveType.id ? formatedReponse : prevState
          )
        );

        setSelectedLeaveType({
          ...formatedReponse,
          leave_type_sub_families:
            formatedReponse.leave_type_sub_families ?? [],
        });
        ToastCustom.validated(t('successUpdate'));
      } else {
        const res = await congesService.post(
          '/v1/leave-types',
          selectedLeaveType
        );
        const startDate = res.data.data.start_date ?? '';
        const endDate = res.data.data.end_date ?? '';

        const formatedReponse = {
          ...res.data.data,
          start_date: startDate,
          end_date: endDate,
        };
        ToastCustom.validated(t('successCreate'));
        setSelectedLeaveType(formatedReponse);
        setLeaveTypes((prevState) => [...prevState, formatedReponse]);
      }
    } catch (err) {
      console.error(err);
      ToastCustom.error(t(err?.response?.data?.message));
    }
  };

  const handleSearchLeaveType = (e) => {
    const searchValue = e.target.value;
    const filteredLeaveTypes = initialLeavesTypes.filter((leaveType) =>
      leaveType.name.toLowerCase().match(searchValue.toLowerCase())
    );
    filteredLeaveTypes.length > 0
      ? setLeaveTypes(filteredLeaveTypes)
      : setLeaveTypes([]);
  };

  const handleSearchAbsences = (e) => {
    const searchValue = e.target.value;
    const filteredAbsences = initialAbsences.filter((absence) =>
      absence.name.toLowerCase().match(searchValue.toLowerCase())
    );
    filteredAbsences.length > 0
      ? setAbsences(filteredAbsences)
      : setAbsences([]);
  };

  const handleDeleteLeaveType = (selectedLeaveType) => {
    SwalCustom.delete(
      (e) => {
        congesService
          .delete(`/v1/leave-types/${selectedLeaveType.id}`)
          .then(() => {
            ToastCustom.validated(t('successDelete'));
            setLeaveTypes((prevState) =>
              prevState.filter(
                (leaveType) => leaveType.id !== selectedLeaveType.id
              )
            );
            setAbsences((prevState) =>
              prevState.filter((absence) => absence.id !== selectedLeaveType.id)
            );
            setSelectedLeaveType(leaveTypes[0]);
          })
          .catch((err) => {
            console.error(err);
          });
      },
      {
        title: t('areYouSureContinue'),
        text: t('deleteAreForever'),
      }
    );
  };

  const handleCreateLeaveType = () => {
    setSelectedLeaveType({
      ...initialLeave,
      name: t('newLeaveType'),
      needs_count: false,
      leave_type_sub_families: [],
      start_date: '',
      end_date: '',
    });
  };

  const handleCreateAbsentee = () => {
    setSelectedLeaveType({
      ...initialLeave,
      name: t('newAbsentee'),
      is_ignore_by_export: true,
      start_date: '',
      end_date: '',
    });
  };

  const handleChangeIsPentecost = (isChecked) => {
    congesService
      .put(`v1/clients/${currentUser.client.id}`, { is_pentecost: isChecked })
      .then((resp) => {
        setCurrentUser((prevState) => ({
          ...prevState,
          client: {
            ...prevState.client,
            is_pentecost: resp.data.data.is_pentecost,
          },
        }));
        ToastCustom.validated(t('successUpdate'));
      })
      .catch((err) => {
        ToastCustom.error(t('errorServer'));
        console.error(err);
      });
  };

  const handleChangeAllowedUser = (value) => {
    congesService
      .put(`v1/clients/${currentUser.client.id}`, {
        is_allowed_to_modify_open_days: value,
      })
      .then((res) => {
        setCurrentUser((prevState) => ({
          ...prevState,
          client: {
            ...prevState.client,
            is_allowed_to_modify_open_days: value,
          },
        }));
        ToastCustom.validated(t('updateSuccess'));
      })
      .catch((err) => {
        console.error(err);
        ToastCustom.error(t('errorServer'));
      });
  };

  const handleChangeCountPublicHoliday = (value) => {
    congesService
      .put(`v1/clients/${currentUser.client.id}`, {
        count_public_holidays: value,
      })
      .then(() => {
        setCurrentUser((prevState) => ({
          ...prevState,
          client: {
            ...prevState.client,
            count_public_holidays: value,
          },
        }));
        ToastCustom.validated(t('updateSuccess'));
      })
      .catch((err) => {
        console.error(err);
        ToastCustom.error(t('errorServer'));
      });
  };

  if (isLoading)
    return (
      <div className='flex justify-center items-center h-full'>
        <Spinner />
      </div>
    );

  return (
    <div className='flex flex-col gap-y-6'>
      <div className='flex justify-end'>
        <div className='flex items-center text-sm bg-gray-100 p-3 gap-x-2 md:text-center sm:text-ellipsis rounded-lg text-secondarylight'>
          <FontAwesomeIcon icon={faInfoCircle} />
          <p className='text-sm text-secondarylight'>
            {t('alertForManagerDescription')}
          </p>
        </div>
      </div>
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {(props) => (
          <>
            <div className='flex flex-wrap items-center gap-x-32 gap-2'>
              <div className='flex flex-col gap-y-3'>
                <h2 className='text-primary1 text-base font-semibold leading-5 mb-2'>
                  {t('workingDays')}
                </h2>
                <OpenDaysCheckbox />
                <InputCustom
                  name='is_allowed_to_modify_open_days'
                  id='is_allowed_to_modify_open_days'
                  type='checkbox'
                  labelClass='text-sm '
                  label={t('toggleOpenDaysTooltip')}
                  checked={props.values.is_allowed_to_modify_open_days}
                  onChange={(e) => {
                    handleChangeAllowedUser(e.target.checked);
                  }}
                />
              </div>
              <div className='flex items-center gap-x-4 mb-2 '></div>
            </div>
            <h2 className='text-primary1 text-base font-semibold leading-5 '>
              {t('whitDays')}
            </h2>
            <div className='flex items-center'>
              <InputCustom
                name='count_public_holidays'
                id='count_public_holidays'
                type='checkbox'
                labelClass='text-sm'
                label={t('activateWhitDaysForLeaves')}
                checked={props.values.count_public_holidays}
                onChange={(e) => {
                  props.setFieldValue(
                    'is_pentecost',
                    props.values.count_public_holidays ? false : true
                  );
                  handleChangeCountPublicHoliday(e.target.checked);
                }}
              />
              <Tooltip title={t('countPublicHolidays')} arrow>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className='cursor-pointer text-lightgrey-500'
                />
              </Tooltip>
            </div>
            <InputCustom
              name='is_pentecost'
              id='is_pentecost'
              type='checkbox'
              label={t('countPentecost')}
              labelClass='text-sm'
              checked={props.values.is_pentecost}
              onChange={(e) => {
                handleChangeIsPentecost(e.target.checked);
              }}
            />
          </>
        )}
      </Formik>
      {/* <div className=''>
          <h2 className='text-primary1 text-base font-semibold leading-5 mb-2'>
            {t('estimatedTakenNtoN1')}
          </h2>
          <ButtonFirstAction
            onClick={() => {
              SwalCustom.delete(
                () => {
                  congesService
                    .post('/v1/leaves/update-future-leaves')
                    .then(() => ToastCustom.validated(t('successUpdate')))
                    .catch((err) => {
                      console.error(err);
                      ToastCustom.error(t('errorServer'));
                    });
                },
                {
                  title: t('areYouSureContinue'),
                  text: t('updateAreForever'),
                  buttonConfirm: t('continue'),
                  buttonCancel: t('cancel'),
                }
              );
            }}
          >
            {t('update')}
          </ButtonFirstAction>
        </div> */}
      <Formik
        initialValues={selectedLeaveType}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = t('requiredField');
          }
          if (!values.leave_code) {
            errors.leave_code = t('requiredField');
          }
          if (values.isDefined && values.end_date === 'Invalid Date') {
            errors.end_date = t('requiredField');
          }
          if (values.isDefined && values.start_date === 'Invalid Date') {
            errors.start_date = t('requiredField');
          }
          !isObjEmpty(errors) && ToastCustom.error(t('pleaseCorrectFields'));
          return errors;
        }}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() =>
          selectedLeaveType.is_only_visible_by_admin
            ? handleSubmitAbsentee()
            : handleSubmit()
        }
      >
        {(props) => (
          <div className='flex flex-wrap gap-y-4 ll:flex-nowrap my-10 justify-between'>
            <div className='flex justify-around w-full ll:flex-col ll:justify-between ll:w-72 ll:mr-6 ll:gap-y-12 max-h-[896px]'>
              {/* Leaves Types */}
              <div className='w-72 shadow-setting-card py-2'>
                <div className='px-2'>
                  <ButtonFilled
                    icon={
                      <FontAwesomeIcon
                        icon={faPlus}
                        className='text-lightgrey-900'
                      />
                    }
                    onClick={handleCreateLeaveType}
                  >
                    {t('createLeaveType')}
                  </ButtonFilled>
                  <p className='text-primary1 my-3 leading-5 font-montserrat font-semibold'>
                    {t('leaveType')}
                  </p>
                </div>

                <LeaveTypesList
                  leaveTypes={leaveTypes}
                  selectedLeaveType={selectedLeaveType}
                  handleSearch={handleSearchLeaveType}
                  setSelectedLeaveType={setSelectedLeaveType}
                  handleDelete={handleDeleteLeaveType}
                />
              </div>
              {/* Absencees */}
              <div className='w-72 shadow-setting-card'>
                <div className='h-9 opacity-0' />
                <p
                  className='text-primary1 px-2 my-3 leading-5 font-montserrat font-semibold flex justify-between items-center'
                  onClick={handleCreateAbsentee}
                >
                  {t('otherTypesAbsentees')}
                  <FontAwesomeIcon
                    icon={faPlusLarge}
                    className='cursor-pointer'
                  />
                </p>
                <LeaveTypesList
                  leaveTypes={absences}
                  selectedLeaveType={selectedLeaveType}
                  handleSearch={handleSearchAbsences}
                  setSelectedLeaveType={setSelectedLeaveType}
                  handleDelete={handleDeleteLeaveType}
                />
              </div>
            </div>
            <div className='w-full shadow-setting-card'>
              <Form className='flex flex-col'>
                <>
                  <div className='flex justify-between'>
                    <div className={'p-3 flex items-center mb-5'}>
                      <div
                        className='w-1 h-6 rounded-3xl mr-2'
                        style={{
                          backgroundColor: selectedLeaveType.color
                            ? selectedLeaveType.color?.replace(/0xFF|0xff/, '#')
                            : '#000000',
                        }}
                      />
                      {selectedLeaveType.name && (
                        <h3 className='text-primary1'>
                          {selectedLeaveType.name}
                        </h3>
                      )}
                    </div>
                    <InputCustom
                      name='is_active'
                      id='is_active'
                      type='switch'
                      checked={selectedLeaveType.is_active}
                      onChange={(e) => {
                        setSelectedLeaveType({
                          ...selectedLeaveType,
                          is_active: e.target.checked,
                        });
                      }}
                    />
                  </div>
                  <div className='flex gap-2 flex-wrap'>
                    <div className='mx-7'>
                      <span className='text-gray-500 text-sm leading-4'>
                        {t('label')}
                      </span>
                      <InputCustom
                        name='name'
                        id='name'
                        type='text'
                        disabled={!selectedLeaveType.is_deletable}
                        value={selectedLeaveType.name}
                        onChange={(e) => {
                          setSelectedLeaveType((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                          }));
                          props.setFieldValue('name', e.target.value);
                        }}
                      />
                    </div>
                    {!selectedLeaveType.is_only_visible_by_admin && (
                      <div className='mx-7'>
                        <span className='text-gray-500 text-sm leading-4'>
                          {t('leaveCode')}
                        </span>
                        <InputCustom
                          name='leave_code'
                          id='leave_code'
                          type='text'
                          disabled={!selectedLeaveType.is_deletable}
                          value={selectedLeaveType.leave_code}
                          onChange={(e) => {
                            setSelectedLeaveType((prevState) => ({
                              ...prevState,
                              leave_code: e.target.value,
                            }));
                            props.setFieldValue('leave_code', e.target.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className='flex my-7 ml-7 gap-16 flex-wrap'>
                    <div className='w-56'>
                      <h3 className='text-primary1 mb-5'>{t('colors')}</h3>
                      <ColorPicker
                        color={selectedLeaveType.color?.replace(
                          /0xFF|0xff/,
                          '#'
                        )}
                        onChange={(e) => {
                          props.setFieldTouched('color', true);
                          props.setFieldValue('color', e.target.value);
                          setSelectedLeaveType((prevState) => ({
                            ...prevState,
                            color: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    {!selectedLeaveType.is_only_visible_by_admin && (
                      <div className='flex flex-col'>
                        <h3 className='text-primary1 mb-5'>{t('counters')}</h3>
                        <div className='flex items-center my-2 mr-2'>
                          <div className='mr-2'>
                            <InputCustom
                              id='is_monthly'
                              name='is_monthly'
                              disabled={!selectedLeaveType.is_deletable}
                              type='radio'
                              value={selectedLeaveType.is_monthly}
                              onChange={(e) => {
                                selectedLeaveType.is_monthly
                                  ? SwalCustom.info(
                                    () => {
                                      setSelectedLeaveType({
                                        ...selectedLeaveType,
                                        is_monthly:
                                          !selectedLeaveType.is_monthly,
                                        leave_type_sub_families: [],
                                      });
                                      props.setFieldValue(
                                        'is_monthly',
                                        !selectedLeaveType.is_monthly
                                      );
                                    },
                                    {
                                      title: t('areYouSure'),
                                      text: t('deleteLeaveTypeCounter'),
                                      confirmButtonText: t('yes'),
                                      cancelButtonText: t('no'),
                                    }
                                  )
                                  : setSelectedLeaveType({
                                    ...selectedLeaveType,
                                    is_monthly: !selectedLeaveType.is_monthly,
                                    leave_type_sub_families: [],
                                  });
                                props.setFieldValue(
                                  'is_monthly',
                                  !selectedLeaveType.is_monthly
                                );
                              }}
                              firstLabel={t('with')}
                              secondLabel={t('without')}
                            />
                          </div>
                          <div className='flex items-center'>
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              className='text-lg mx-1 text-lightgrey-500'
                            />
                            <p className='text-xs text-lightgrey-500'>
                              {t('withCounterInfo')}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {!selectedLeaveType.is_only_visible_by_admin && (
                    <>
                      <AdvancedSettings
                        selectedLeaveType={selectedLeaveType}
                        setSelectedLeaveType={setSelectedLeaveType}
                      />
                      <hr className='border-gray-200 border-1 my-10' />
                      <MaxConsecutiveDays
                        selectedLeaveType={selectedLeaveType}
                        setSelectedLeaveType={setSelectedLeaveType}
                      />
                    </>
                  )}
                  <div className='flex my-10 mx-7'>
                    <ButtonFirstAction
                      children={t('save')}
                      type='submit'
                      disabled={isObjEmpty(props.touched) || props.isSubmitting}
                    />
                  </div>
                </>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default Leaves;
