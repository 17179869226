import React, { useContext, useEffect, useState } from 'react';
import { faArrowDownToLine, faChevronRight, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import congesService from '@data/congesService';
import SwalCustom from '@helpers/SwalCustom';
import { useTranslation } from 'react-i18next';
import ToastCustom from '@components/UI/ToastCustom';
import { Plus } from 'lucide-react';
import DocumentationModal from '@components/Setting/Documentation/DocumentationModal';
import Lottie from 'lottie-react';
import empty from '@assets/lottie/empty';

import Spinner from '@components/UI/Spinner';
import UserContext from '@context/UserContext';

const Documentation = () => {
  const [documents, setDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const { t } = useTranslation();

  const { currentUser } = useContext(UserContext);

  const isAdmin = ['ADMINISTRATEURMANAGER', 'ADMINISTRATEUR'].includes(
    currentUser.profile.label
  );

  const fetchDocuments = async () => {
    try {
      const response = await congesService.post(
        '/v1/support-documents/search'
      );
      setDocuments(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async (media) => {
    SwalCustom.delete(
      async () => {
        try {
          await congesService.delete(`/v1/support-documents/${media.id}`);
          ToastCustom.validated('Document supprimé avec succès');
          fetchDocuments();
        } catch (err) {
          console.error(err);
        }
      },
      {
        title: t('areYouSureContinue'),
        text: t('deleteAreForever'),
      }
    );
  };

  const handleDownload = async (media) => {
    try {
      const response = await congesService.get(`/v1/media/${media.id}/download`, {
        responseType: 'blob',
      });
      const newBlob = new Blob([response.data], { type: media.mime_type });
      const url = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.setAttribute('download', `${media.name}`);
      link.setAttribute('href', url);
      document.body.appendChild(link);
      link.click();
      link.remove();

      ToastCustom.validated("Téléchargement réussi");
    } catch (err) {
      console.error(err);
      ToastCustom.error(`Une erreur s'est produite lors du téléchargement`);
    }
  };

  const fetchData = async () => {
    await fetchDocuments();
  };

  const getImageType = (media) => {

    const mimeType = media.mime_type.split('/')[1];
    switch (mimeType) {
      case 'pdf':
        return '/icons/pdf.svg';
      case 'png':
        return '/icons/png.svg';
      case 'jpeg':
      case 'jpg':
        return '/icons/jpg.svg';
      default:
        return '/icons/png.svg';
    }
  };

  useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <h1 className={'mb-6 mt-10'}>{t('documentation')}</h1>
      <div className='bg-white w-full rounded-md p-7 h-[700px]'>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className=' w-full h-full'>
            {isAdmin && (
              <button
                className="flex items-center text-blue-500 hover:text-blue-700 cursor-pointer font-lato text-sm justify-end"
                onClick={() => setIsModalOpen(true)}
                style={{ marginLeft: 'auto' }}
              >
                <Plus className="h-5 w-5 mr-2" />
                Ajouter un document
              </button>
            )}
            <div className='flex flex-col space-y-5 mt-5'>
              {documents.length > 0 ? (
                documents.map((doc) => (
                  <div className='flex w-full' key={doc.id}>
                    <a className='cursor-pointer flex' href={doc.media[0].original_url} target='_blank'>
                      <img src={getImageType(doc.media[0])} alt="Type icon" className='h-9 w-9' />
                      <div className="ml-2 flex items-center justify-center">
                        <span className='font-normal font-lato text-sm text-lightgrey-400'>{doc.name}</span>
                      </div>
                    </a>
                    <div className='flex items-center space-x-2 text-lightgrey-500 ml-auto'>
                      {isAdmin && <FontAwesomeIcon icon={faTrash} className='w-4 h-4 cursor-pointer' onClick={() => handleDelete(doc)} />}
                      <FontAwesomeIcon icon={faArrowDownToLine} className='w-4 h-4 cursor-pointer' onClick={() => handleDownload(doc.media[0])} />
                      <a href={doc.media[0].original_url} target='_blank'>
                        <FontAwesomeIcon icon={faChevronRight} className='w-4 h-4 cursor-pointer' />
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Lottie animationData={empty} loop={true} style={{ height: '200px', width: '200px' }} />
                  <h4 className='font-lato text-lightgrey-500 mt-5 text-lg'>{t('noDocuments')}</h4>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <DocumentationModal
        sites={sites}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        fetchDocuments={fetchDocuments}
      />
    </>
  );
};

export default Documentation;
