export const params = [
  {
    title: 'Paramétrage par défaut',
    slug: 'default',
  },
  {
    title: 'Paramétrage des sites',
    slug: 'site',
  },
];

export const defaultAbsenceType = {
  name: 'Nouvelle absence',
  leave_code: 'ABS',
  color: '#000000',
  is_only_visible_by_admin: true,
  is_attachment_required: false,
  is_active: true,
  is_default: false,
  is_paid: false,
  can_exceed: false,
  is_deletable: true,
  is_half_day: false,
  is_ignore_by_export: true,
  is_monthly: false,
  start_date: '',
  end_date: '',
  order_appearance: 0,
  leave_type_category_id: 2,
};

export const initialLeave = {
  name: '',
  leave_code: '',
  color: '#000000',
  is_only_visible_by_admin: false,
  is_attachment_required: false,
  is_active: true,
  is_default: false,
  is_paid: false,
  can_exceed: false,
  is_deletable: true,
  is_half_day: false,
  is_ignore_by_export: false,
  is_monthly: false,
  start_date: '',
  end_date: '',
  order_appearance: 0,
  leave_type_category_id: 1,
};
