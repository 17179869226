// ** Default import

import { useState, useContext } from 'react';
import UserContext from '@context/UserContext';

// ** Utils

import { useTranslation } from 'react-i18next';
import congesService from '@src/data/congesService';
import { getFullname } from '@utility/Utils';

// ** Teams filters config

const PlanningConfig = (
  setFilter,
  setUsers,
  isTag,
  setIsFilterModalOpen,
  selectedOptions,
  setSelectedOptions,
  setSelectedModels,
  setPage
) => {
  const { t } = useTranslation();
  const [userPage, setUserPage] = useState(1);
  const { currentUser } = useContext(UserContext);

  const handleSubmit = (e) => {
    buildFilter(selectedOptions);
    setIsFilterModalOpen(false);
  };

  const handleClose = () => {
    setUserPage(1);
    setIsFilterModalOpen(false);
  };

  const buildFilter = (options) => {
    setUsers([]);
    setPage(1);
    let filterToSend = [];
    Object.keys(options).forEach((key) => {
      if (options[key] && options[key].length > 0) {
        if (Array.isArray(options[key])) {
          if (key === '_id') {
            const siteIds = options[key].filter((obj) => obj.type === 'site');
            if (siteIds.length > 0) {
              const sites = siteIds.map(
                (siteString) => siteString.id.split('_')[1]
              );
              filterToSend.push({
                field: 'site.id',
                operator: 'in',
                value: sites,
              });
              setSelectedModels(sites);
            }
            const tagIds = options[key].filter((obj) => obj.type === 'tag');
            if (tagIds.length > 0) {
              const tags = tagIds.map(
                (tagString) => tagString.id.split('_')[1]
              );
              filterToSend.push({
                field: 'tags.id',
                operator: 'in',
                value: tags,
              });
              setSelectedModels(tags);
            }
          } else {
            setSelectedModels([]);
            filterToSend.push({
              field: 'id',
              operator: 'in',
              value: options[key].map((item) => item.id),
            });
          }
        }
      }
    });
    setFilter(filterToSend);
    setUserPage(1);
  };

  const loadOptions = async (inputValue) => {
    const isAdmin =
      currentUser?.profile?.label === 'ADMINISTRATEUR' ||
      currentUser?.profile?.label === 'ADMINISTRATEURMANAGER';

    const isDirector = currentUser?.profile?.label === 'DIRECTOR';
    let dataToSend = {
      'search-users': {
        value: inputValue,
      },
      sort: [{ field: 'lastname', direction: 'asc' }],
      scopes: [{ name: 'viewUndirectManagedUsers' }],
    };

    if (isAdmin || isDirector) {
      delete dataToSend.scopes;
    }
    if (!inputValue) {
      delete dataToSend.search;
    }

    const response = await congesService.post(
      `v1/users/search?page=${userPage}`,
      dataToSend
    );
    const meta = response.data.meta;
    setUserPage(meta.current_page < meta.last_page ? meta.current_page + 1 : 1);
    return {
      options: response.data.data.map((res) => {
        return {
          ...res,
          name: getFullname(res),
        };
      }),
      hasMore: meta?.current_page !== meta?.last_page,
    };
  };

  const loadSiteAndTeams = async (inputValue) => {
    let options = [];
    if (isTag) {
      const tagResponse = await congesService.post(`v1/tags/search`, {
        search: {
          value: `${inputValue}`,
        },
        filters: [
          {
            field: 'user_id',
            operator: '=',
            value: currentUser.id,
          },
        ],
      });
      tagResponse.data.data.map((tag) => {
        options.push({
          type: 'tag',
          id: `tag_${tag.id}`,
          name: tag.label,
        });
      });
    } else {
      const siteResponse = await congesService.post(`v1/sites/search`, {
        search: {
          value: `${inputValue}`,
        },
      });
      siteResponse.data.data.map((site) => {
        options.push({
          type: 'site',
          id: `site_${site.id}`,
          name: site.name,
        });
      });
    }
    return {
      options: options,
      hasMore: false,
    };
  };

  const handleReset = () => {
    setSelectedOptions({});
    setFilter([]);
    setSelectedModels([]);
    setUsers([]);
  };

  const fields = [
    {
      name: 'collaborator',
      label: t('collaborator'),
      type: 'asyncPaginate',
      value: selectedOptions['user_id'] ?? [],
      placeholder: t('collaborators'),
      notFoundMessage: 'Aucun collaborateur trouvé',
      optionLabel: 'name',
      optionValue: 'id',
      isMulti: true,
      onChange: (e) => {
        setSelectedOptions({
          ...selectedOptions,
          user_id: e,
        });
      },
      loadOptions: loadOptions,
      additional: {
        page: userPage,
      },
    },
    {
      name: 'sitesAndTeams',
      label: isTag ? t('tags') : t('sites'),
      type: 'asyncPaginate',
      loadOptions: loadSiteAndTeams,
      value: selectedOptions['_id'] ?? [],
      placeholder: isTag ? t('tags') : t('sites'),
      optionLabel: 'name',
      optionValue: 'id',
      isMulti: true,
      onChange: (e) => {
        setSelectedOptions({
          ...selectedOptions,
          _id: e,
        });
      },
    },
  ];

  return {
    title: t('filters'),
    fields,
    handleSubmit: handleSubmit,
    handleClose: handleClose,
    handleReset: handleReset,
  };
};

export default PlanningConfig;
