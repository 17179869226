import { weekDayIds } from '../../data/days';

export const formatDays = (daysResponse) => {
  const initialDaysSelectedIds = daysResponse.map((day) => day.id);
  let days = {};
  weekDayIds.forEach(
    ({ id, name }) => (days[name] = initialDaysSelectedIds.includes(id))
  );
  return days;
};

export const getAbsencesTypes = (leaves) =>
  leaves.filter((leave) => leave.leave_type_category.slug === 'abs');
