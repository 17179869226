import React from 'react'


export default function Card({ children, className = '' }) {
  return (
    <div className={`bg-white rounded-lg border border-grey-400 shadow ${className}`}>
      {children}
    </div>
  )
}
