import React, { useContext, useEffect, useState } from 'react';
import congesService from '@data/congesService';
import CollapseLeftBar from '../components/Planning/components/collapseLeftBar/collapseLeftBar';
import PlanningComponent from '../components/Planning/components/planning/planningComponent';
import PageHeader from '../components/Planning/components/pageHeader/pageHeader';
import PlanningContext from '@context/PlanningContext';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport,
  faArrowUpFromLine,
} from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from '@mui/material';
import ToastCustom from '@components/UI/ToastCustom';
import dayjs from 'dayjs';
import { getFullname } from '@utility/Utils';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import { downloadError } from '../utility/imports';
import Spinner from '@components/UI/Spinner';
import ExportModal from '@components/Teams/ExportModal';

import UserContext from '@context/UserContext';
import { Loader } from 'lucide-react';

dayjs.extend(weekOfYear);
dayjs.extend(weekday);

const Planning = () => {
  const { t } = useTranslation();

  const { currentUser } = useContext(UserContext);

  const isAdmin = ['ADMINISTRATEUR', 'ADMINISTRATEURMANAGER'].includes(
    currentUser?.profile?.label
  );
  const isDirector = currentUser?.profile?.label === 'DIRECTOR';

  const [users, setUsers] = useState([]);
  const [allUserLength, setAllUserLength] = useState(0);
  const [usersGroupedBySite, setUsersGroupedBySite] = useState([]);

  const [page, setPage] = useState(1);
  const [hoverUser, setHoverUser] = useState(null);
  //selected range period
  //Possible values : twomonths - month - week
  const [period, setPeriod] = useState('twomonths');

  const [reversePrimary, setReversePrimary] = useState(
    localStorage.getItem('reverse_primary') ?? false
  );

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  useEffect(() => {
    setReversePrimary(localStorage.getItem('reverse_primary'));
  }, []);

  //Current date data of planning
  const [month, setMonth] = useState(dayjs().month());
  const [week, setWeek] = useState(dayjs().week());
  const [year, setYear] = useState(dayjs().year());

  //array of dates that represents all dates in period
  const [daysOfPeriod, setDaysOfPeriod] = useState([]);

  //Planning filters
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [isTagsDisplay, setIsTagsDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [reloadLeaves, setReloadLeaves] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [leaveTypesN1, setLeaveTypesN1] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState({});

  const [publicHolidays, setPublicHolidays] = useState([]);

  //Holidays
  const holidayZones = [
    { label: 'a', color: '#E77272' },
    { label: 'b', color: '#C90500' },
    { label: 'c', color: '#640200' },
  ];

  //Previous or next week period navigation
  const changeWeek = (value) => {
    setDaysOfPeriod([]);
    if (week === 1 && value < 0) {
      setWeek(52);
      changeMonth(-1);
    } else if (week === 52 && value > 0) {
      setWeek(1);
      changeMonth(1);
    } else {
      const newWeek = week + value;
      if (newWeek % 4 === 0 && value > 0) {
        changeMonth(1);
      } else if (newWeek % 4 === 0 && value < 0) {
        changeMonth(-1);
      }
      setWeek(newWeek);
    }
  };

  //Navigate to previous or next month
  const changeMonth = (value) => {
    setDaysOfPeriod([]);
    if (month === 11 && value > 0) {
      setYear((y) => y + 1);
      setMonth(0);
    } else if (month === 0 && value < 0) {
      setYear((y) => y - 1);
      setMonth(11);
    } else {
      setMonth((m) => m + value);
    }
  };

  //Generate an array
  const initDaysOfPeriod = () => {
    let days = [];

    if (period === 'week') {
      const firstDay = dayjs().year(year).month(month).week(week).weekday(0);
      days = Array.from({ length: 7 }, (v, n) => firstDay.add(n, 'day'));
    } else {
      const dayOfPeriod = dayjs().year(year).month(month);
      if (period === 'twomonths') {
        const nextMonthDate = dayjs().year(year).month(month).add(1, 'month');
        days = [
          ...Array.from({ length: dayOfPeriod.daysInMonth() }, (v, n) =>
            dayOfPeriod.date(1).add(n, 'day')
          ),
          ...Array.from({ length: nextMonthDate.daysInMonth() }, (v, n) =>
            nextMonthDate.date(1).add(n, 'day')
          ),
        ];
      } else {
        days = Array.from({ length: dayOfPeriod.daysInMonth() }, (v, n) =>
          dayOfPeriod.date(1).add(n, 'day')
        );
      }
    }
    setDaysOfPeriod(days);
  };

  const fetchUsers = async (resetData = false) => {
    let dataToSend = {};
    setIsLoading(true);
    if (isTagsDisplay) {
      dataToSend = {
        filters: filters,
        sort: [
          { field: `site.name`, direction: 'asc' },
          { field: `lastname`, direction: 'asc' },
        ],
      };
    } else {
      dataToSend = {
        filters: filters,
        scopes: [
          {
            name: 'viewUndirectManagedUsers',
          },
        ],
        sort: [
          { field: `site.name`, direction: 'asc' },
          { field: `lastname`, direction: 'asc' },
        ],
      };
      if (isAdmin || isDirector) {
        delete dataToSend.scopes;
      }
    }

    try {
      const res = await congesService.post(
        `/v1/users/search?page=${page}&limit=30&withCurrentUser&include=user_leave_counts,site,days${
          isTagsDisplay ? '&planningTags' : ''
        }`,
        {
          ...dataToSend,
          aggregates: [
            {
              relation: 'directors',
              type: 'exists',
              filters: [
                {
                  field: 'directors.director_id',
                  operator: '=',
                  value: currentUser.id,
                },
              ],
            },
          ],
        }
      );
      setAllUserLength(res.data.meta.total);
      let fetchedUsers = [];
      resetData
        ? fetchedUsers(res.data.data)
        : (fetchedUsers = [...users, ...res.data.data]);
      extractSitesAndTagsFromUsers(fetchedUsers);
      setUsers(fetchedUsers);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const extractSitesAndTagsFromUsers = (userList) => {
    const groupModel = userList.reduce((group, user) => {
      let category = '';
      if (isTagsDisplay && user.tag_id) {
        category = `tag_${user.tag_label}_${user.tag_id}`;
      } else {
        category = `site_${user.site.name}_${user.site.id}`;
      }
      group[category] = group[category] ?? [];
      group[category].push(user);
      return group;
    }, {});
    let userGroupBySites = [];
    for (const [key, value] of Object.entries(groupModel)) {
      userGroupBySites.push({
        name: key,
        users: value,
      });
    }
    const userGroupBySitesSorted = userGroupBySites.map(({ name, users }) => {
      const usersSorted = users
        .map((user) => {
          return { ...user, name: getFullname(user) };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      return {
        name,
        users: usersSorted,
      };
    });

    setUsersGroupedBySite(userGroupBySitesSorted);
  };

  function addUser(userList) {
    if (isTagsDisplay) return resetOptions();

    const userListWithDirector = userList.map((user) => {
      return { ...user, directors_exists: true };
    });

    setUsers((prev) => [...prev, ...userListWithDirector]);
    extractSitesAndTagsFromUsers([...users, ...userListWithDirector].flat());
  }

  function detachUser(userList) {
    const newUsersList = users.filter((user) => user.id !== userList[0].id);
    extractSitesAndTagsFromUsers(newUsersList);
    setUsers(newUsersList);
  }

  const resetOptions = () => {
    setFilters([]);
    setUsers([]);
    setPage(1);
    setIsTagsDisplay(false);
    setSelectedModels([]);
  };

  const importUsers = async (formData) => {
    try {
      ToastCustom.status('Chargement de votre fichier en cours...');
      const res = await congesService.post(`/v1/import-leaves`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.data.success === 'PartiallySucces') {
        ToastCustom.submitted(res.data.message);
        downloadError(res.data.errors);
      } else {
        ToastCustom.validated(res.data.message);
      }
    } catch (err) {
      ToastCustom.error(err.response.data.message);
      console.error(err);
    } finally {
      setLoadingExport(false);
    }
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  const exportUsers = (values, props) => {
    props.setSubmitting(true);
    congesService
      .post(
        `/v1/export-leaves`,
        {
          file_name: values.file_name,
          filters: filters,
        },
        {
          responseType: 'blob',
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute(
          'download',
          `${
            values.file_name.length !== 0 ? values.file_name : 'export'
          }-${dayjs().format('DD/MM/YYYY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        ToastCustom.validated(t('successExport'));
      })
      .catch((err) => {
        console.error(err);
        ToastCustom.error(t('errorDownload'));
      })
      .finally(() => {
        props.setSubmitting(false);
      });
  };

  const fetchLeaveType = async () => {
    try {
      const res = await congesService.post(`/v1/leave-types/search`, {
        filters: [
          {
            field: 'is_active',
            operator: '=',
            value: true,
          },
        ],
        sort: [
          {
            field: 'order_appearance',
            direction: 'asc',
          },
        ],
      });

      setLeaveTypes(
        res.data.data.map((lt) => {
          return {
            ...lt,
            color: lt.color.replace(/0xFF|0xff/, '#'),
          };
        })
      );
      const result = [];

      res.data.data
        .filter((lt) => lt.needs_count)
        .forEach((lt) => {
          if (lt.is_pay) {
            result.push({
              ...lt,
              name: `${lt.name} N-1`,
            });
            result.push(lt);
          } else {
            result.push(lt);
          }
        });
      setLeaveTypesN1(result);
    } catch (err) {
      console.error(err);
    }
  };

  const handleClick = () => {
    setIsLoading(true);
    setPage(page + 1);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files?.[0];
    const validMimeTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    if (validMimeTypes.includes(file.type)) {
      const formData = new FormData();
      formData.append('file', file);
      importUsers(formData);
      fetchUsers(true);
      setReloadLeaves(true);
      setLoadingExport(true);
    } else {
      ToastCustom.error('Le fichier doit être au format CSV, XLS ou XLSX');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [isTagsDisplay, filters, page]);

  useEffect(() => {
    initDaysOfPeriod();
  }, [period, month, week]);

  useEffect(() => {
    fetchLeaveType();
    return () => {
      setLeaveTypes([]);
      setLeaveTypesN1([]);
    };
  }, []);

  return (
    <PlanningContext.Provider
      value={{
        t,
        period,
        setPeriod,
        reversePrimary,
        setReversePrimary,
        week,
        month,
        year,
        changeWeek,
        changeMonth,
        daysOfPeriod,
        usersGroupedBySite,
        setIsFilterModalOpen,
        isFilterModalOpen,
        filters,
        setFilters,
        setPage,
        holidayZones,
        fetchUsers,
        users,
        setUsers,
        setIsTagsDisplay,
        isTagsDisplay,
        setSelectedModels,
        selectedModels,
        publicHolidays,
        setPublicHolidays,
        hoverUser,
        setHoverUser,
        isAdmin,
        addUser,
        detachUser,
        reloadLeaves,
        setReloadLeaves,
      }}
    >
      {isExportModalOpen && (
        <ExportModal
          isOpen={isExportModalOpen}
          onModalClose={toggleExportModal}
          exportToExcel={exportUsers}
        />
      )}
      <div className='flex justify-between'>
        <h1 className={'mb-6'}>{t('planning')}</h1>
        {isAdmin && (
          <div className='flex items-center space-x-6'>
            <Tooltip
              title={
                'Importer les congés et les absences de vos collaborateurs (.csv, .xls, .xlsx)'
              }
              arrow
            >
              <div className='flex items-center ml-5 cursor-pointer'>
                <input
                  type='file'
                  id='fileInput'
                  className='hidden'
                  onChange={(event) => {
                    handleFileSelect(event);
                    event.target.value = null;
                  }}
                />
                <label
                  htmlFor='fileInput'
                  className='flex items-center cursor-pointer'
                >
                  <FontAwesomeIcon icon={faArrowUpFromLine} />
                  <p className='ml-2'>Importer</p>
                </label>
              </div>
            </Tooltip>
            {/* <Tooltip title={'Exporter les congés et les absences de vos collaborateurs'} arrow>
            <div className="flex items-center cursor-pointer" onClick={toggleExportModal}>
              <FontAwesomeIcon icon={faFileExport} />
              <p className="ml-2">Exporter</p>
            </div>
          </Tooltip> */}
            {/* <Tooltip title={`Télécharger un modèle d'import`} arrow>
            <div className="flex items-center cursor-pointer">
              <FontAwesomeIcon icon={faDownload} />
              <p className="ml-2">Télécharger un modèle</p>
            </div>
          </Tooltip> */}
          </div>
        )}
      </div>
      <div className='bg-white rounded-md py-7 min-h-[99vh]'>
        <PageHeader
          leaveTypes={leaveTypes}
          filters={filters}
          setFilters={setFilters}
          setSelectedOptions={setSelectedOptions}
        />
        <hr />
        {reloadLeaves && loadingExport ? (
          <Spinner />
        ) : (
          <>
            <div className='flex flex-row'>
              <CollapseLeftBar leaveTypes={leaveTypesN1} isAdmin={isAdmin} />
              <PlanningComponent
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
              />
            </div>
            <div className='flex items-center justify-center relative mt-4'>
              {users?.length < allUserLength && (
                <div
                  className='bg-[#E20917] rounded-[4px] cursor-pointer py-2 px-3 text-white text-sm ml-[300px] hover:bg-[#B10713]'
                  onClick={handleClick}
                >
                  {isLoading ? (
                    <Loader className='animate-spin-slow text-white' />
                  ) : (
                    'Charger plus de collaborateurs'
                  )}
                </div>
              )}
              <p className='absolute right-4 text-xs text-lightgrey-400'>
                Collaborateurs: {users?.length} / {allUserLength}
              </p>
            </div>
          </>
        )}
      </div>
    </PlanningContext.Provider>
  );
};

export default Planning;
