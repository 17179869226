import React from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import InputCustom from '@components/UI/InputCustom';
import ModalCustom from '@components/UI/ModalCustom';
import { ButtonFirstAction, ButtonSecondAction } from '@components/UI/Buttons';
import Spinner from '@components/UI/Spinner';

const ExportModal = ({ isOpen, onModalClose, exportToExcel }) => {
  const { t } = useTranslation();

  return (
    <ModalCustom
      isOpen={isOpen}
      onModalClose={onModalClose}
      customClass={'w-8/12 md:w-[900px] h-[270px]'}
      title={t('export')}
      children={
        <>
          <Formik
            initialValues={{
              file_name: '',
            }}
            onSubmit={exportToExcel}
          >
            {(props) => {
              return (
                <Form>
                  <InputCustom
                    label={t('name')}
                    type='text'
                    name='file_name'
                    placeholder={t('name')}
                    containerClass='my-6'
                  />
                  <div className='flex justify-end gap-x-6 items-center'>
                    <ButtonFirstAction type='button' onClick={onModalClose}>
                      {t('cancel')}
                    </ButtonFirstAction>
                    <div className="flex items-center justify-center h-[80px] w-[120px] mr-4">
                      {props.isSubmitting ? (
                        <Spinner height={80} width={80} />
                      ) : (
                        <ButtonSecondAction
                          type='submit'
                          disabled={props.isSubmitting}
                        >
                          {t('continue')}
                        </ButtonSecondAction>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      }
    />
  );
};

export default ExportModal;
